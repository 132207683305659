<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table :search="searchData" :items="filteredData" :headers="dataHeader" v-if="filteredData!=null && !api.isLoading" dense class="elevation-1">
            <template v-slot:item.data="{item}">
                {{ JSON.stringify(item.data) }}
            </template>
            <template v-slot:top>
                <v-toolbar flat height="auto">
                    <v-row no-gutters>  
                        <v-col class="ma-4">
                            <v-row>
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Coupons
                                </v-toolbar-title>
                                <v-spacer>

                                </v-spacer>
                                <v-btn color="primary" @click="validateInput">
                                    Re-Apply
                                </v-btn>
                            </v-row>
                            <v-row class="pt-4">
                                    <v-select class="mx-2" :items="filterOptions" v-model="fitlerSelected" dense outlined @change="filterCouponBasedOnStatus">
                                    </v-select>

                                    <v-text-field class="mx-2" v-model="searchData" dense outlined label="Search...">
                                    </v-text-field>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>

            <!-- <template v-slot:item.data.company_name="{item}">
                <span> {{ (item.company_name!=null)?item.company_name:"-" }}</span>
            </template> -->
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        searchData:null,
        filteredData:null,
        filterOptions:["pending","completed","failed","cancelled"],
        fitlerSelected:"failed",
        data:null,
        dataHeader:[
            {
                text:'Code',
                value:'code',
            },
            {
                text:'Company',
                value:'data.company_name',
            },
            {
                text:'Description',
                value:'description',
            },
            {
                text:'Status',
                value:'log_status',
            },
            {
                text:'Log Data',
                value:'data'
            }
          
        ],
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="fetchAppliedCoupon"){
                this.data = resp.data;
                this.filterCouponBasedOnStatus();
            }
            if(resp.class=="reApplyFailedCoupon"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Re-Apply the coupon"
                location.reload();
                
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchFailedCouponApi = this.fetchFailedCoupon();

            this.$api.fetch(fetchFailedCouponApi);
        },
        fetchFailedCoupon(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/coupons/failed';
            return tempApi;
        },
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        reApplyCoupon(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/coupons/failed/reapply";
            return tempApi;

        },
        filterCouponBasedOnStatus(){
            let tempData = this.$_.clone(this.data);
            // let tempFilterOption = this.$_.clone(this.filterOptions);
            let tempFilterSelected = this.$_.clone(this.fitlerSelected);
            let tempDataFiltered = tempData.filter(function(item){
                return item.log_status===tempFilterSelected;
            });
            this.filteredData = tempDataFiltered;
            return 1;


        }
    }
}
</script>